import { render, staticRenderFns } from "./BigNews.vue?vue&type=template&id=9657a0f0&scoped=true&lang=pug&"
import script from "./BigNews.vue?vue&type=script&lang=js&"
export * from "./BigNews.vue?vue&type=script&lang=js&"
import style0 from "./BigNews.vue?vue&type=style&index=0&id=9657a0f0&prod&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9657a0f0",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BigNewsModal: require('/opt/buildhome/repo/components/BigNewsModal.vue').default})
