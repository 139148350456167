
export default {
  name: 'CookieConsent',
  data() {
    return {
      requireConsent: false,
    }
  },
  mounted() {
    let userConsent = false
    try {
      userConsent =
        Boolean(this.$cookiz.get('panda-cookie-consent')) || Boolean(localStorage.getItem('panda-cookie-consent'))
      if (userConsent) {
        this.$utils.cookies.set('panda-cookie-consent', true)
      }
    } catch (e) {}
    this.requireConsent = !userConsent
  },
  methods: {
    consent() {
      this.$utils.cookies.set('panda-cookie-consent', true)
      this.requireConsent = false
    },
  },
}
