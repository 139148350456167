
import { mapState } from 'vuex'
import Markdown from '@/components/Markdown.vue'
export default {
  name: 'BigNewsModal',
  components: {
    Markdown,
  },
  props: {
    modalVisible: {
      type: Boolean,
      default: false,
    },
    news: {
      type: Object,
      default: () => {},
    },
    doNotShow: {
      type: Function,
      default: () => function () {},
    },
    hideNeverShowAgain: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    klLink() {
      return `${window.location.origin}/knowledges/${this.news.id}`
    },
    editLink() {
      return `/knowledges/${this.news.id}/edit`
    },
    ...mapState({
      session: (state) => state.session.session,
      user: (state) => state.user.user,
      isAdmin: (state) => state.session.isAdmin,
    }),
  },
  beforeDestroy() {
    this.updateParentSwitchVal()
  },
  methods: {
    updateParentSwitchVal() {
      this.$emit('update:modalVisible', false)
    },
    _doNotShow() {
      this.doNotShow(this.news)
      this.updateParentSwitchVal()
    },
    processContent(content) {
      return content.replace(/{{siteDomain}}/g, location.host)
    },
    getAttr(obj, key) {
      const langKey = `${key}_${this.$i18n.locale}`
      const ret = obj[langKey] || obj[key]
      if (key === 'content' && ret) {
        return this.processContent(ret)
      }
      return ret
    },
  },
}
