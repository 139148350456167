
// import Logo from '@/components/Logo'
export default {
  // components: {
  //   Logo
  // },
  props: {
    modalVisible: {
      type: Boolean,
      default: false,
    },
    defaultState: {
      type: String,
      default: 'signup',
    },
  },
  data() {
    return {
      loginInfo: {
        email: null,
        password: null,
        ref: this.$utils.cookies.get('refer') || null,
      },
      loggingIn: false,
      state: this.defaultState,
      email_token: '',
    }
  },
  computed: {
    titleText() {
      switch (this.state) {
        case 'signup':
          return this.$t('login.signup_button')
        case 'login':
          return this.$t('login.button')
        case 'reset':
          return this.$t('login.reset.button')
        default:
          return ''
      }
    },
    mail_link() {
      return `mailto:passwordchange@mg.1s.life?subject=${this.email_token}:您的新密码`
    },
  },
  watch: {
    defaultState(newVal) {
      this.state = newVal
    },
  },
  beforeDestroy() {
    this.updateParentSwitchVal()
  },
  methods: {
    updateParentSwitchVal() {
      this.$emit('update:modalVisible', false)
    },
    async onSubmit() {
      if (this.state !== 'reset' && (!this.loginInfo.email || !this.loginInfo.password)) {
        return this.$message({
          message: this.$t('login.email_or_password_please'),
          type: 'error',
        })
      }
      this.loggingIn = true
      try {
        await this[this.state]()
        this.loggingIn = false
        this.afterWards()
      } catch (e) {
        console.log(e)
        this.loggingIn = false
        this.$notify({
          type: 'error',
          title: `${this.$t('error.str')} ${e.response?.status}`,
          message: this.$t(e.response?.data?.message || e.response?.data || e),
        })
      }
    },
    async signup() {
      // console.log(this.loginInfo)
      await this.$store.dispatch('session/signUp', this.loginInfo)
      // console.log('session is now:', this.$store.state.session.session)
      return this.$store.dispatch('user/setUserData', { user: this.$store.state.session.session })
    },
    async login() {
      await this.$store.dispatch('session/login', this.loginInfo)
      return this.$store.dispatch('user/setUserData', { user: this.$store.state.session.session })
    },
    async reset() {
      if (!this.loginInfo.email) {
        this.$message({
          message: this.$t('login.email_please'),
          type: 'error',
        })
        return (this.loggingIn = false)
      }
      try {
        const { data } = await this.$store.dispatch('user/requestPasswordReset', { email: this.loginInfo.email })
        this.$alert(this.$t('login.reset.reset_requested'), this.$t('login.check_email'), {
          confirmButtonText: this.$t('ok'),
        }).catch(() => {})
        this.email_token = data.email_token
      } catch (err) {
        if (err.response?.data?.message === 'illegal email' || err.response?.data?.message === 'not found') {
          return this.$message({
            message: this.$t('login.not_found'),
            type: 'error',
          })
        }
        return this.$message({
          message: 'Error:' + err.message,
          type: 'error',
        })
      }
    },
    // 可以增加一个afterwards prop，让调用的传入function，这样就自动回调上级的代码去处理后续
    // 然后这里就是默认的后续操作
    afterWards() {
      console.log('afterWards', this.state)
      if (!['signup', 'login'].includes(this.state)) {
        console.log('gonna return.')
        return
      }
      this.updateParentSwitchVal()
      if (['pricing', 'knowledges-knowledgeid'].includes(this.$route.name.toLowerCase())) {
        // this.$router
        // 关闭自己
        // 确认下这样parent value是否更新
        this.localVisible = false
        window.location.reload()
      } else {
        this.$router.push(`/dashboard/${this.$store.state.session.session.id}`)
      }
    },
  },
}
