export const state = () => ({})

export const mutations = {}

export const actions = {
  /**
   * @param {Object} _
   * @param {Object} opt
   */
  getAll(_, opt) {
    return this.$axios.get(`servers`, { params: opt })
  },
  delete(_, opt) {
    return this.$axios.delete(`servers/${opt.serverId}`)
  },
  update(_, { serverId, data }) {
    return this.$axios.put(`servers/${serverId}`, data)
  },
  create(_, opt) {
    return this.$axios.post(`servers`, opt)
  },
}
