export const state = () => ({
  // serviceArray: [],
  // allplatform: null,
  // home: null,
  // currentServiceCate: null,
  // usableServiceCate: [],
  // dataCache: {},
  // servers: {}
})

export const mutations = {
  alipayHistory({ state }, data) {},
}

export const actions = {
  getAlipayHistory({ state }, { userId, opt }) {
    return this.$axios.get(`users/${userId}/alipays`, { params: opt })
  },
  getEuroPaymentHistory({ state }, { userId, opt }) {
    return this.$axios.get(`users/${userId}/paypals`, { params: opt })
  },
  getRefHistory({ state }, { userId, opt }) {
    return this.$axios.get(`users/${userId}/refer`, { params: opt })
  },
  getPaymentHistory({ state }, { userId, opt }) {
    return this.$axios.get(`users/${userId}/payments`, { params: opt })
  },
  getManualTopupHistory({ state }, { userId, opt }) {
    return this.$axios.get(`users/${userId}/manual_topups`, { params: opt })
  },
  // 使用兑换码充值
  getRedeemHistory({ state }, { userId, opt }) {
    return this.$axios.get(`users/${userId}/redeem_history`, { params: opt })
  },
  // 购买兑换码
  getRedeemPurchaseHistory({ state }, { userId, opt }) {
    return this.$axios.get(`users/${userId}/redeem`, { params: opt })
  },
  pingxxRefund({ state }, opt) {
    return this.$axios.post(`pingxx/refund`, opt)
  },
  stripeRefund({ state }, opt) {
    return this.$axios.post(`stripe/refund`, opt)
  },
  yabandRefund({ state }, opt) {
    return this.$axios.post(`yaband/refund`, opt)
  },
  paypalRefund({ state }, opt) {
    return this.$axios.post(`paypal/refund`, opt)
  },
  paymentwallRefund(context, opt) {
    return this.$axios.post(`paymentwall/refund`, opt)
  },
  adyenRefund(context, opt) {
    return this.$axios.post(`adyen/refund`, opt)
  },
  checkLostPingxxPayments({ state }, { userId }) {
    return this.$axios.post(`pingxx/check_bills/${userId}`)
  },
  checkAlipayIdsBlocked({ state }, params) {
    return this.$axios.get(`admin/blocked_payer`, { params })
  },
  checkLostStripePayments({ state }, { userId }) {
    return this.$axios.post(`stripe/check_bills/${userId}`)
  },
  checkLostPaypalPayments({ state }, { userId }) {
    return this.$axios.post(`paypal/check_bills/${userId}`)
  },
  checkLostPaymentwallPayments({ state }, { userId }) {
    return this.$axios.post(`paymentwall/check_bills/${userId}`)
  },
  checkLostAdyenPayments({ state }, { userId }) {
    return this.$axios.post(`adyen/check_bills/${userId}`)
  },
  blockPayer({ state }, opt) {
    return this.$axios.post(`admin/block_payer`, opt)
  },
  unblockPayer({ state }, opt) {
    return this.$axios.post(`admin/unblock_payer`, opt)
  },
  getUserFapiaoHistory({ state }, params) {
    return this.$axios.get(`admin/fapiao_history`, { params })
  },
  addFapiaoHistory({ state }, opt) {
    return this.$axios.post(`admin/fapiao_history`, opt)
  },
  delFapiaoHistory({ state }, objId) {
    return this.$axios.delete(`admin/fapiao_history/${objId}`)
  },
  getInvoiceAuthToken({ state }, { userId }) {
    return this.$axios.get(`users/${userId}/invoices/auth-token`)
  },
  getStripeChargeDetails({ state }, { id }) {
    return this.$axios.get(`stripe/charge_details`, {
      params: {
        id,
      },
    })
  },
}
