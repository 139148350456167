
// import { Viewer } from '@toast-ui/vue-editor'
export default {
  name: 'Markdown',
  components: {
    [process.client && 'Viewer']: () => {
      const promise = import('@toast-ui/vue-editor')
      return promise.then((r) => {
        return r.Viewer
      })
    },
  },
  props: {
    content: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    options: {
      height: '100%',
      // previewStyle: 'vertical',
      hideModeSwitch: true,
      usageStatistics: false,
    },
    asyncViewer: '',
  }),
  watch: {
    content(newVal) {
      // console.log('mdViewer:', newVal)
      this.$refs.mdViewer.invoke('setMarkdown', newVal, false)
    },
  },
  created() {
    if (process.client) {
      console.log('this is', this)
      try {
        this.asyncViewer = 'Viewer'
      } catch (e) {
        console.log('Error loading viewer (mounted hook)', e)
      }
    }
  },
}
