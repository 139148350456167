import Vue from 'vue'
import VueI18n from 'vue-i18n'
// import en from '../lang/en.json'
// import zh from '../lang/zh.json'
import { languages as messages } from '../lang'

export default ({ app, store }) => {
  Vue.use(VueI18n)
  // console.log('en:', en)
  app.i18n = new VueI18n({
    // @ts-ignore
    locales: ['en', 'zh'],
    locale: store.state.base.locale,
    fallbackLocale: 'zh',
    silentTranslationWarn: true,
    messages,
  })
}
