import { set } from 'vue'
export const state = () => ({
  user: {},
})

export const mutations = {
  user(state, data) {
    // console.log('mutating user:', data)
    data.money = Number(data.money)
    state.user = data
  },
  updateBilling(state, billing) {
    state.user.billing = billing
  },
  setEmailToken2(state, token2) {
    state.user.extra.email_token2 = token2
  },
  setEmailVerified(state, verified) {
    state.user.extra.email_verified = verified
  },
  updateExtra(state, extraData) {
    set(state.user, 'extra', { ...state.user.extra, ...extraData })
  },
  announcementEmail(state, sub) {
    state.user.no_email = sub
  },
  userEmail(state, newEmail) {
    state.user.email = newEmail
  },
  updateBalance(state, newBalance) {
    state.user.money = newBalance
  },
}

export const actions = {
  async get({ commit }, opt) {
    const { data } = await this.$axios.get(`users/${opt.userId}`)
    // console.log('got user data:', data)
    commit('user', data)
  },
  setUserData({ commit }, opt) {
    // console.log('setUserData:', opt.user)
    commit('user', opt.user)
  },
  requestPasswordReset(context, opt) {
    return this.$axios.post('users/reset_password', opt)
  },
  async updateBilling({ commit }, { billing, userId }) {
    const { data } = await this.$axios.post(`users/${userId}/billing`, { billing })
    commit('updateBilling', billing)
    return data
  },
  async requstEmailVerification({ commit }, { userId }) {
    const { data } = await this.$axios.post(`users/${userId}/email_verify`)
    commit('setEmailToken2', data.email_token2)
    return data
  },
  async setEmailVerified({ commit }, { userId }) {
    await this.$axios.put(`users/${userId}/set_email_verified`)
    commit('setEmailVerified', true)
    return true
  },
  async announcementEmail({ commit }, { userId, sub }) {
    await this.$axios.put(`users/${userId}/no_email`, { no_email: !sub })
    commit('announcementEmail', !sub)
    return !sub
  },
  async changeEmail({ commit }, { userId, opt }) {
    await this.$axios.put(`users/${userId}/email`, opt)
    commit('userEmail', opt.new_email)
    return true
  },
  changePassword({ commit }, { userId, opt }) {
    return this.$axios.put(`users/${userId}/password`, opt)
  },
  getEmailRecords({ commit }, { userId, opt }) {
    return this.$axios.get(`users/${userId}/email_records`, { params: opt })
  },
  getLoginRecords({ commit }, { userId, opt }) {
    return this.$axios.get(`users/${userId}/login_records`, { params: opt })
  },
  getClientLogs({ commit }, { userId, opt }) {
    return this.$axios.get(`users/${userId}/client_log_submit`, { params: opt })
  },
  getNewPassword({ commit }, opt) {
    return this.$axios.post(`users/reset_password/confirm`, opt)
  },
  updateExtra({ commit }, extraData) {
    commit('updateExtra', extraData)
  },
  async barkNotify(_, opt) {
    return (await this.$axios.post(`apn-bark/${opt.userId}/notify`, opt)).data
  },
  async getBarkDevices(_, { userId }) {
    return (await this.$axios.get(`apn-bark/${userId}/devices`)).data
  },
  async updateBarkDevice(_, { userId, deviceToken, data }) {
    return (await this.$axios.put(`apn-bark/${userId}/${deviceToken}`, data)).data
  },
  async delBarkDevice(_, { userId, deviceToken }) {
    return (await this.$axios.delete(`apn-bark/${userId}/${deviceToken}`)).data
  },
  async getAllowedPaymentMethods(_, { userId }) {
    return (await this.$axios.get(`users/${userId}/allowed_payment_methods`)).data
  },
}
