import { render, staticRenderFns } from "./IndexFooter.vue?vue&type=template&id=c619509e&scoped=true&lang=pug&"
import script from "./IndexFooter.vue?vue&type=script&lang=js&"
export * from "./IndexFooter.vue?vue&type=script&lang=js&"
import style0 from "./IndexFooter.vue?vue&type=style&index=0&id=c619509e&prod&lang=postcss&scoped=true&"
import style1 from "./IndexFooter.vue?vue&type=style&index=1&id=c619509e&prod&lang=postcss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c619509e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ColorModePicker2: require('/opt/buildhome/repo/components/ColorModePicker2.vue').default,BigNews: require('/opt/buildhome/repo/components/BigNews.vue').default})
