import { render, staticRenderFns } from "./BigNewsModal.vue?vue&type=template&id=3cb60c6b&lang=pug&"
import script from "./BigNewsModal.vue?vue&type=script&lang=js&"
export * from "./BigNewsModal.vue?vue&type=script&lang=js&"
import style0 from "./BigNewsModal.vue?vue&type=style&index=0&id=3cb60c6b&prod&lang=postcss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Markdown: require('/opt/buildhome/repo/components/Markdown.vue').default})
