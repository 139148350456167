export const state = () => ({})

export const mutations = {}

export const actions = {
  confirmPrice({ state, rootState }, { userId, opt }) {
    return this.$axios
      .post(`users/${userId || rootState.user.id}/services`, opt)
      .then((ret) => {
        return Promise.resolve(ret.data)
      })
      .catch((err) => {
        console.log(err)
        return Promise.reject(err.response || err)
      })
  },
  pingppCheckout({ state }, { opt, params }) {
    return this.$axios
      .post(`pingxx`, opt, { params })
      .then((ret) => {
        return Promise.resolve(ret.data)
      })
      .catch((err) => {
        console.log(err)
        return Promise.reject(err.response || err)
      })
  },
  createStripeWechat({ state }, { opt, params }) {
    return this.$axios
      .post(`stripe_wechat`, opt, { params })
      .then((ret) => {
        return Promise.resolve(ret.data)
      })
      .catch((err) => {
        console.log(err)
        return Promise.reject(err.response || err)
      })
  },
  createStripeAlipay({ state }, { opt, params }) {
    return this.$axios
      .post(`stripe_alipay`, opt, { params })
      .then((ret) => {
        return Promise.resolve(ret.data)
      })
      .catch((err) => {
        console.log(err)
        return Promise.reject(err.response || err)
      })
  },
  createYaband({ state }, { opt: { channel, opt }, params }) {
    // console.log(channel, opt)
    return this.$axios
      .post(`yaband_${channel}`, opt, { params })
      .then((ret) => {
        return Promise.resolve(ret.data)
      })
      .catch((err) => {
        console.log(err)
        return Promise.reject(err.response || err)
      })
  },
  createStripeCheckoutSession({ state, commit }, { opt, params }) {
    console.log('params:...', `stripe/checkout-session`, opt, { params })
    return this.$axios
      .post(`stripe/checkout-session`, opt, { params })
      .then((ret) => {
        return Promise.resolve(ret.data)
      })
      .catch((err) => {
        console.log(err)
        return Promise.reject(err.response || err)
      })
  },
  createPaypal(context, { opt, params }) {
    return this.$axios
      .post(`paypal`, opt, { params })
      .then((ret) => {
        return Promise.resolve(ret.data)
      })
      .catch((err) => {
        console.log(err)
        return Promise.reject(err.response || err)
      })
  },
  createPaymentwall(context, { opt, params }) {
    return this.$axios
      .post(`paymentwall`, opt, { params })
      .then((ret) => {
        return Promise.resolve(ret.data)
      })
      .catch((err) => {
        console.log(err)
        return Promise.reject(err.response || err)
      })
  },
  createAdyen(context, opt) {
    return this.$axios
      .post(`adyen`, opt)
      .then((ret) => {
        return Promise.resolve(ret.data)
      })
      .catch((err) => {
        console.log(err)
        return Promise.reject(err.response || err)
      })
  },
  checkPromoCode({ state }, opt) {
    return this.$axios.get(`promocodes/${opt.promoCode}/discount`)
  },
  stripeCapture({ state }, opt) {
    return this.$axios.post('stripe/capture', opt)
  },
  adyenCapture({ state }, opt) {
    return this.$axios.post('adyen/capture', opt)
  },
  getPaypalRemoteDetails({ state }, { orderId, params }) {
    return this.$axios.get(`paypal/${orderId}/remote-details`, { params })
  },
  getRMBPaymentCount({ state }, { userId }) {
    return this.$axios.get(`users/${userId}/rmb_payment_count`)
  },
}
