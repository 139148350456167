export const state = () => ({})

export const mutations = {
  alipayHistory({ state }, data) {},
}

export const actions = {
  getAll({ state }, { params }) {
    return this.$axios.get(`knowledges`, {
      params,
    })
  },
  get({ state }, { knowledgeId, params }) {
    return this.$axios.get(`knowledges/${knowledgeId}`, { params })
  },
  update({ state }, { knowledgeId, opt }) {
    return this.$axios.put(`knowledges/${knowledgeId}`, opt)
  },
  delete({ state }, { knowledgeId }) {
    return this.$axios.delete(`knowledges/${knowledgeId}`)
  },
  add({ state }, knowledge) {
    return this.$axios.post(`knowledges`, knowledge)
  },
  getLastestNews({ state }, params) {
    return this.$axios.get(`big_news`, { params })
  },
  // @getKnowledges = (is_news)->
  //   $http.get KNOWLEDGE_API_POINT,
  //     params:
  //       is_news: is_news

  // @getBigNews = (history)->
  //   $http.get BIG_NEWS_API_POINT,
  //     params:
  //       history: history or 0

  // @getKnowledge  = (id)->
  //   $http.get KNOWLEDGE_API_POINT + "/#{id}"

  // @addOrSaveKnowledges = (knowledge)->
  //   return $http.post KNOWLEDGE_API_POINT, knowledge if not knowledge.id?
  //   $http.put KNOWLEDGE_API_POINT+"/#{knowledge.id}", knowledge

  // @deleteKnowledge = (id)->
  //   $http.delete KNOWLEDGE_API_POINT+"/#{id}"
}
