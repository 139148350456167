import { Loading } from 'element-ui'
export default async function ({ redirect, route, store, app, error }) {
  const loading = Loading.service({
    lock: true,
    text: 'Loading',
    spinner: 'el-icon-loading',
    background: 'rgba(0, 0, 0, 0.7)',
  })
  if (store.state.session.loggedIn) {
    if (route.query.asUser && store.state.session.isAdmin) {
      await store.dispatch('user/get', { userId: route.query.asUser })
    } else {
      await store.dispatch('user/setUserData', { user: store.state.session.session })
    }
  }
  loading?.close()
}
