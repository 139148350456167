import { Loading } from 'element-ui'
// const checkToken = (token) => token !== 'undefined' && token !== 'null' && token
export default async function ({ app, store }) {
  let loading
  try {
    let token = app.$utils.cookies.get('jwt_token')
    if (!token) {
      token = localStorage?.getItem('jwt_token')
    }
    if (!store.state.session.loggedIn && app.$utils.checkToken(token)) {
      app.$utils.cookies.set('jwt_token', token)
      loading = Loading.service({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      })
      await store.dispatch('session/restoreSession')
      if (loading) {
        loading?.close()
      }
    } else if (!app.$utils.checkToken(token)) {
      localStorage?.removeItem('jwt_token')
      app.$utils.cookies.remove('jwt_token')
      app.$utils.cookies.remove('panda-news-read')
      app.$utils.cookies.remove('preferredServiceCate')
    }
  } catch (e) {
    console.log('restore session error:', e)
    if (loading) {
      loading?.close()
    }
  }
}
