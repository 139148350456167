export const state = () => ({
  session: null,
  viewingSelf: false,
  isAdmin: false,
  loggedIn: false,
  intercomProfile: null,
  ip: null,
})

export const mutations = {
  login(state, session) {
    state.session = session
    state.isAdmin = session.admin
    state.loggedIn = true
  },
  logout: (state) => {
    state.session = null
    state.loggedIn = false
  },
  setIP: (state, ip) => {
    state.ip = ip
  },
  intercomProfile: (state, data) => {
    state.intercomProfile = data
  },
  isAdmin: (state, isAdmin) => {
    state.isAdmin = isAdmin
  },
}

export const actions = {
  async login(context, opt) {
    const session = (await this.$axios.post('sessions', opt)).data
    context.dispatch('setupSession', session)
  },
  async signUp(context, opt) {
    const session = (await this.$axios.post('users', opt)).data
    const ga = context.$ga || this.$ga
    if (ga) {
      ga.event('Engagement', 'signUp')
    }
    context.dispatch('setupSession', session)
  },
  logout({ commit }) {
    // this.$axios.setToken(false)
    delete this.$axios.defaults.headers.common['x-panda-authorization']
    localStorage?.removeItem('jwt_token')
    this.$utils.cookies.remove('jwt_token')
    this.$utils.cookies.remove('panda-news-read')
    this.$utils.cookies.remove('preferredServiceCate')
    if (this.$intercom?.ready) {
      this.$intercom.shutdown()
    }
    commit('logout')
  },
  async getIP({ commit }) {
    const ip = await this.$axios.get('http://icanhazip.com')
    commit('setIP', ip)
  },
  setupSession(context, session) {
    // console.log('setupSessionsetupSessionsetupSessionsetupSession')
    // console.log(session)
    this.$axios.defaults.headers.common['x-panda-authorization'] = session.jwt_token
    if (session.jwt_token) {
      this.$utils.cookies.set('jwt_token', session.jwt_token)
    } else {
      console.log('setupSession error, jwt_token not exist, session is:', session)
    }
    const ga = context.$ga || this.$ga
    if (ga) {
      // console.log('ga', ga)
      ga.set('userId', `${session.id}`)
    }
    this.$sentry.setUser(session)
    context.commit('login', session)
  },
  async restoreSession(context) {
    // console.log('restoreSession action called.')
    // console.log('this.$utils is', this.$utils)
    let token = this.$cookiz.get('jwt_token')
    if (!token) {
      token = localStorage?.getItem('jwt_token')
    }
    if (!this.$utils.checkToken(token)) {
      this.$utils.cookies.set('jwt_token', token)
      return
    }
    if (context.state.session) {
      return
    }
    // console.log('Token:', token)
    // this.$axios.setToken(token)
    this.$axios.defaults.headers.common['x-panda-authorization'] = token
    const session = (await this.$axios.get('sessions')).data
    if (session.user_id === -1) {
      return context.dispatch('logout')
    }
    context.dispatch('setupSession', session)
  },
  async getIntercomProfile({ commit }, { userId }) {
    const { data } = await this.$axios.get(`users/${userId}/intercom_profile`)
    commit('intercomProfile', data)
  },
  viewingSelf({ commit }, booleanVal) {
    commit('viewingSelf', booleanVal)
  },
}
