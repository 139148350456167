export default function ({ store, error, route }) {
  if (!store.state.session.isAdmin) {
    if (store.state.user.user?.extra?.suspend) {
      return error({
        statusCode: 403,
        message: 'errors.account_suspended.message',
        title: 'errors.account_suspended.title',
      })
    }
  }
  // console.log('route is:', route)
}
