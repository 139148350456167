import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc' // dependent on utc plugin
import timezone from 'dayjs/plugin/timezone'
import relativeTime from 'dayjs/plugin/relativeTime'
import euList from '~/static/euList'
dayjs.extend(relativeTime)
dayjs.extend(utc)
dayjs.extend(timezone)

const ua = () => {
  if (process.browser || process?.client) {
    return navigator?.userAgent.toLowerCase() || ''
  } else {
    return ''
  }
}

const cookieOptions = {
  expires: new Date(Date.now() + 1000 * 60 * 60 * 24 * 180),
  sameSite: 'none',
  secure: true,
  path: '/',
}

export default (context, inject) => {
  // console.log('-->>>>>context is', context)
  const eurToRmb = (eur) => {
    if (!eur) {
      return 0
    }
    return moneyShape(eur * context.$config.rmbToEurRate)
  }
  const toEur = (rmb) => {
    if (!rmb) {
      return 0
    }
    return this.moneyShape((rmb / context.$config.rmbToEurRate + 0.25) / 0.971)
  }
  const moneyShape = (input) => {
    return Math.ceil(input * 100) / 100
  }
  const utils = {
    hello: (msg) => console.log(`Hello ${msg}!`),
    money: (input) => Math.floor(Number(input) * 100) / 100,
    toDate: (input) => new Date(input),
    isEu: (countryName) => euList.includes(countryName),
    setQuery: (query) => {
      let queryStr = []
      for (const key of Object.keys(query)) {
        queryStr.push(key + '=' + query[key])
      }
      if (queryStr.length) {
        queryStr = `?${queryStr.join('&')}`
      }
      window.history.replaceState(
        {},
        window.document.title,
        `${window.location.origin}${window.location.pathname}${queryStr}`
      )
    },
    checkToken: (token) => token !== 'undefined' && token !== 'null' && token,
    dateStr: (input, inBeijingTime) =>
      inBeijingTime
        ? dayjs(input).tz('Asia/Shanghai').format(`YYYY/M/D HH:mm:ss Z`)
        : dayjs(input).format(`YYYY/M/D HH:mm:ss Z`),
    dateStrShort: (input, inBeijingTime) =>
      inBeijingTime ? dayjs(input).tz('Asia/Shanghai').format(`YYYY/M/D`) : dayjs(input).format(`YYYY/M/D`),
    futureTime: (_pastT, inBeijingTime) => {
      const now = dayjs()
      const pastT = dayjs(_pastT)
      if (inBeijingTime) {
        pastT.tz('Asia/Shanghai')
        now.tz('Asia/Shanghai')
      }
      return pastT.to(now)
    },
    relativeTime: (_futureT, inBeijingTime) => {
      const now = dayjs()
      const futureT = dayjs(_futureT)
      if (inBeijingTime) {
        futureT.tz('Asia/Shanghai')
        now.tz('Asia/Shanghai')
      }
      return now.to(futureT)
    },
    currency: (amount, currency = 'CNY') => {
      if (Intl) {
        return new Intl.NumberFormat(navigator?.language, {
          style: 'currency',
          currency,
        }).format(amount)
      }
      const signMapping = {
        CNY: '¥',
        EUR: '€',
        USD: '$',
      }
      return `${signMapping[currency.toUpperCase()]} ${amount.toFixed(2)}`
    },
    getDaysInMonth: (dayjsObj) => {
      return new Date(dayjsObj.year(), dayjsObj.month() + 1, 0).getDate()
    },
    openSite: (u) => {
      const ret = window.open(u, '_blank')
      if (!ret) {
        location.href = u
        return 'self'
      }
      return '_blank'
    },
    copy: (content) => {
      if (process?.client && navigator?.clipboard) {
        navigator?.clipboard.writeText(content)
      } else {
        const textArea = document.createElement('textarea')
        textArea.value = content
        textArea.style.position = 'fixed'
        document.body.appendChild(textArea)
        textArea.focus()
        textArea.select()
        try {
          document.execCommand('copy')
          document.body.removeChild(textArea)
        } catch (err) {
          document.body.removeChild(textArea)
          throw err
        }
      }
    },
    stringifyData: (data, channel, urlencode) => {
      const hasOwn = {}.hasOwnProperty
      if (typeof urlencode === 'undefined') {
        urlencode = false
      }
      const output = []
      let i
      for (i in data) {
        if (!hasOwn.call(data, i) || typeof data[i] === 'function') {
          continue
        }
        if (channel === 'bfb_wap' && i === 'url') {
          continue
        }
        if (channel === 'yeepay_wap' && i === 'mode') {
          continue
        }
        if (i === 'channel_url') {
          continue
        }
        output.push(i + '=' + (urlencode ? encodeURIComponent(data[i]) : data[i]))
      }
      return output.join('&')
    },
    ua,
    isInWeChat() {
      return /micromessenger/i.test(ua())
    },
    toType(obj) {
      return {}.toString
        .call(obj)
        .match(/\s([a-zA-Z]+)/)[1]
        .toLowerCase()
    },
    capitalize: (str) => str.charAt(0).toUpperCase() + str.slice(1),
    stringToColorHex: (str) => {
      if (!str) {
        return 'red'
      }
      let hash = 0
      for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash)
      }
      let colour = '#'
      for (let i = 0; i < 3; i++) {
        const value = (hash >> (i * 8)) & 0xff
        colour += ('00' + value.toString(16)).substr(-2)
      }
      return colour
    },
    toCurrencyString: (amount, currency) => {
      if (currency === 'CNY') {
        if (window.Intl) {
          return new window.Intl.NumberFormat('zh-CN', { style: 'currency', currency: 'CNY' }).format(eurToRmb(amount))
        }
        return `¥ ${amount.toFixed(2)}`
      }
      // EUR
      if (window.Intl) {
        console.log('===>>>> Intl is available.')
        return new window.Intl.NumberFormat('en-US', { style: 'currency', currency }).format(amount)
      }
      return `€ ${amount.toFixed(2)}`
    },
    geoStr(geoObj) {
      return `${geoObj.countryName === '中国' ? '' : geoObj.countryName || ''} ${geoObj.regionName || ''} ${
        geoObj.cityName || ''
      }`
    },
    eurToRmb,
    moneyShape,
    toEur,
    cookies: {
      ...context.$cookiz,
      set: (name, value, options = {}) => {
        context.$cookiz.set(name, value, { ...cookieOptions, ...options })
      },
      remove: (name, options = {}) => {
        context.$cookiz.remove(name, { ...cookieOptions, ...options })
      },
    },
    wait: (ms) => new Promise((resolve) => setTimeout(resolve, ms)),
  }
  // console.log('utils', utils.cookies)
  inject('utils', utils)
}
