
import dayjs from 'dayjs'
import { mapState } from 'vuex'
import utc from 'dayjs/plugin/utc' // dependent on utc plugin
import timezone from 'dayjs/plugin/timezone'
dayjs.extend(utc)
dayjs.extend(timezone)

export default {
  name: 'SimpleCurrentServiceSelector',
  data() {
    return {}
  },
  computed: {
    currentServiceCate: {
      get() {
        return this.$store.state.service.currentServiceCate
      },
      set(value) {
        const s = this.$store.state.service[value]
        if (this.$store.state.service[value]) {
          this.$store.dispatch('service/currentServiceCate', value)
          return
          // eslint-disable-next-line no-unreachable
          if (s.status === 'off') {
            let reason = 'expired'
            const buyT = dayjs(s.extra.buy_time).tz('Asia/Shanghai')
            const nowInBJ = dayjs().tz('Asia/Shanghai')
            if (nowInBJ.isSame(buyT, 'month')) {
              reason = 'all_data_used'
              if (s.extra.buy_length > 1) {
                reason = 'current_month_all_data_used'
              }
            }
            // console.log('alerting...')
            return this.$confirm(this.$t(reason), this.$t(`services.${value}.name`), {
              confirmButtonText: this.$t('service_selector.go_order'),
              cancelButtonText: this.$t('service_selector.cancel'),
              type: 'error',
              closeOnPressEscape: false,
              closeOnClickModal: false,
            }).then(
              () => {
                this.$router.push(`/pricing?asUser=${this.user.id}&cate=${value}`)
              },
              () => {}
            )
          }
        } else {
          return this.$confirm(this.$t('not_bought'), this.$t(`services.${value}.name`), {
            confirmButtonText: this.$t('service_selector.go_order'),
            cancelButtonText: this.$t('service_selector.cancel'),
            type: 'info',
            closeOnPressEscape: false,
            closeOnClickModal: false,
          }).then(
            () => {
              this.$router.push(`/pricing?asUser=${this.user.id}&cate=${value}`)
            },
            () => {}
          )
        }
      },
    },
    serviceOptions() {
      return [{ value: 'allplatform' }, { value: 'home' }]
    },
    ...mapState({
      user: (state) => state.user.user,
    }),
  },
}
