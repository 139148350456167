
import { mapState } from 'vuex'
import BigNewsModal from '@/components/BigNewsModal.vue'
export default {
  name: 'BigNews',
  components: {
    BigNewsModal,
  },
  data() {
    return {
      rawNews: [],
      readKey: 'panda-news-read',
      readIds: [],
      newToShow: {},
      modalVisible: false,
    }
  },
  async fetch() {
    const ret = (await this.$store.dispatch('knowledge/getLastestNews', { history: 1 })).data
    if (ret) {
      this.rawNews = ret
    }
  },
  computed: {
    validNews() {
      return this.rawNews?.filter?.((news) => !news.expired && !this.hasRead(news) && news.id !== 108)
    },
    screenWidth() {
      return window.innerWidth
    },
    userRead() {
      const read = this.$cookiz.get(this.readKey)
      return this.readIds.concat(JSON.parse(read || '[]'))
    },
    shouldShow() {
      // console.log('validNews:', this.validNews)
      if (this.modalVisible) {
        return false
      }
      if (!this.validNews.length) {
        // console.log('no valid news.')
        return false
      }
      if (this.$route?.name?.startsWith('knowledges')) {
        // console.log('in knowledges, do not show.')
        return false
      }
      if (this.isAdmin) {
        if (!this.user?.id) {
          // console.log('no user info. will show news.')
          return true
        }
        if (this.session?.id !== this.user?.id) {
          // console.log('user is not session. will NOT show news.')
          return false
        }
      }
      return true
    },
    ...mapState({
      session: (state) => state.session.session,
      user: (state) => state.user.user,
      isAdmin: (state) => state.session.isAdmin,
    }),
  },
  methods: {
    fontSize(content) {
      let size = 70 / content.length
      const maxSize = 1.8
      const minSize = 1.2
      if (size > maxSize) {
        size = maxSize
      }
      if (size < minSize) {
        size = minSize
      }
      return `${(size / 290) * this.screenWidth}px`
    },
    showNews(news) {
      this.newToShow = news
      this.modalVisible = true
    },
    hasRead(news) {
      console.log('userRead:', this.userRead)
      return this.userRead.includes(news.id)
    },
    doNotShow(news) {
      const newRead = [news.id, ...this.userRead]
      this.$utils.cookies.set(this.readKey, JSON.stringify(newRead))
      this.readIds.push(news.id)
    },
    getAttr(obj, key) {
      return obj[`${key}_${this.$i18n.locale}`] || obj[key]
    },
  },
}
