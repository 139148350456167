export const state = () => ({})

export const mutations = {}

export const actions = {
  /**
   * @param {Object} _
   * @param {Object} opt
   */
  suspendAccount(_, opt) {
    return this.$axios.post(`admin/suspend_account`, opt)
  },
  allowSea(_, opt) {
    return this.$axios.post(`admin/allow_sea`, opt)
  },
  blockUserPayment(_, opt) {
    return this.$axios.post(`admin/block_user_payment`, opt)
  },
  unblockUserPayment(_, opt) {
    return this.$axios.post(`admin/unblock_user_payment`, opt)
  },
  blockUserPayPalPayer({ state }, opt) {
    return this.$axios.post('admin/block_user_paypal_payer', opt)
  },
  unblockUserPayPalPayer({ state }, opt) {
    return this.$axios.post(`admin/unblock_user_paypal_payer`, opt)
  },
  blockUserDevice(_, opt) {
    return this.$axios.post(`admin/block_user_device`, opt)
  },
  unblockUserDevice(_, opt) {
    return this.$axios.post(`admin/unblock_user_device`, opt)
  },
  addData(_, opt) {
    return this.$axios.post(`admin/addquota`, opt)
  },
  delService(_, opt) {
    return this.$axios.post(`admin/delservice`, opt)
  },
  shadowBanService(_, opt) {
    return this.$axios.post(`admin/shadow_ban_service`, opt)
  },
  addMoney(_, opt) {
    return this.$axios.post(`admin/addmoney`, opt)
  },
  getOverLimitRecords(_, opt) {
    return this.$axios.post(`admin/over_limit`, opt)
  },
  getOverLimitServiceInfo(_, opt) {
    return this.$axios.post(`admin/over_limit_service_info`, opt)
  },
  getOverLimitWarningHistory(_, opt) {
    return this.$axios.post(`admin/over_limit_warning`, opt)
  },
  sendOverLimitWarning(_, opt) {
    return this.$axios.post(`admin/send_over_limit_warning`, opt)
  },
  getAllAPServers(_, opt) {
    return this.$axios.get(`admin/all_ap_servers`, { params: opt })
  },
  getRealTimeSpeed(_, opt) {
    return this.$axios.get(`funstuff/real_time_speed`)
  },
  getEmailRender(_, opt) {
    return this.$axios.get(`funstuff/md_render`, { params: opt })
  },
  sendEmail(_, opt) {
    return this.$axios.post(`admin/send_email`, opt)
  },
  useDynamicPMWUserId(_, opt) {
    return this.$axios.post(`admin/dynamic_pmw_user_id`, opt)
  },
  delRealTimeSpeedServer(_, opt) {
    return this.$axios.put(`funstuff/real_time_speed`, opt)
  },
  getAlibabaTrans(_, opt) {
    return this.$axios.get(`funstuff/get_alibabacloud_trans`, { params: opt })
  },
  getServerStatSpeed(_, opt) {
    return this.$axios.get(`admin/servertrspeed`, { params: opt })
  },
  getServerList(_, opt) {
    return this.$axios.get(`admin/server_list`, { params: opt })
  },
  getDownServers(_, opt) {
    return this.$axios.get(`admin/downservers`, { params: opt })
  },
  getFailedServers(_, opt) {
    return this.$axios.get(`admin/failedservers`, { params: opt })
  },
  getStatLastPost(_, { type, params }) {
    return this.$axios.get(`admin/${type}_stat_last_post`, { params })
  },
  delFailedServers(_, opt) {
    return this.$axios.put(`admin/failedservers`, opt)
  },
  setDownServers(_, opt) {
    return this.$axios.post(`admin/downservers`, opt)
  },
  setDLClientVersion(_, opt) {
    console.log('setDLClientVersion', opt)
    return this.$axios.post(`admin/client_version`, opt)
  },
  callAdmin(_, opt) {
    return this.$axios.get(`admin/call_admin`, { params: opt })
  },
  switchDoNotCall(_, opt) {
    return this.$axios.post(`admin/do_not_call`, opt)
  },
  purgeEmailAndConfCache(_, opt) {
    return this.$axios.delete(`admin/clear_email_file_cache`)
  },
  setDownReason(_, opt) {
    return this.$axios.post(`admin/status_reason`, opt)
  },
  updateMonitor(_, opt) {
    return this.$axios.post(`admin/update_monitor_server_list`, opt)
  },
  setDomainAndDelimiter(_, opt) {
    return this.$axios.post(`admin/client_site_url`, opt)
  },
  getServerSpeedRecords(_, opt) {
    return this.$axios.get(`funstuff/server_speed_records`, { params: opt })
  },
  getClientHeartBeatRecords(_, opt) {
    return this.$axios.get(`client/heart_beat`, { params: opt })
  },
  remoteExecute(_, opt) {
    return this.$axios.post(`admin/remote_execute`, opt)
  },
  writeFile(_, opt) {
    return this.$axios.post(`admin/write_file_to_server`, opt)
  },
  getTrAbuser(_, opt) {
    return this.$axios.get(`admin/tr_abuser`, { params: opt })
  },
  getTrAbuserWarningRecords(_, opt) {
    return this.$axios.post(`admin/tr_abuser_warning_records`, opt)
  },
  sendTrAbuseWarning(_, opt) {
    return this.$axios.post(`admin/send_tr_abuse_warning`, opt)
  },
  getTrAbuseNotes(_, opt) {
    return this.$axios.post(`admin/get_tr_abuse_notes`, opt)
  },
  addTrAbuseNote(_, opt) {
    return this.$axios.post(`admin/tr_abuse_notes`, opt)
  },
  delTrAbuseNote(_, opt) {
    return this.$axios.delete(`admin/tr_abuse_notes/${opt.noteId}`)
  },
  fetchAccessLog(_, opt) {
    return this.$axios.get(`admin/download_access_log`, { params: opt })
  },
  getIPGeo(_, opt) {
    return this.$axios.post(`funstuff/ipgeo`, opt)
  },
  getNFStatus(_, opt) {
    return this.$axios.get(`nf_status`, { params: opt })
  },
  getPushLog(_, { logId, params }) {
    return this.$axios.get(`admin/error_logs/${logId}`, { params })
  },
  deleteUser(_, opt) {
    return this.$axios.post(`admin/delete_user`, opt)
  },
  async setAllowedPaymentMethod({ commit, rootState }, { userId, allowedPaymentMethods }) {
    const { data } = await this.$axios.post(`admin/allowed_payment_methods`, {
      user_id: userId,
      allowed_payment_methods: allowedPaymentMethods,
    })
    commit(
      'user/updateExtra',
      {
        ...{ ...rootState.user.user.extra },
        ...{ allowed_payment_methods: allowedPaymentMethods },
      },
      { root: true }
    )
    return data
  },
  async getDailySum(_, params) {
    return await this.$axios.get(`finance/daily-sum`, { params })
  },
  async getAccessLog(_, opt) {
    return (await this.$axios.post(`admin/get_access_log`, opt)).data
  },
  async sendPush(_, opt) {
    return (await this.$axios.post(`admin/send_push`, opt)).data
  },
  async getPrismaMetrics(_, opt) {
    return (await this.$axios.get(`admin/prisma_metrics`, { params: opt })).data
  },
  async getServiceTestResults(_, opt) {
    return (await this.$axios.post(`admin/get_service_test_results`, opt)).data
  },
  async getPMWTransactionsHTML(_, params) {
    return (await this.$axios.get(`admin/pmw_transactions_html`, { params })).data
  },
  async switchAutoChangePort(_, opt) {
    return (await this.$axios.post(`admin/auto_change_port`, opt)).data
  },
}
