export const state = () => ({})

export const mutations = {}

export const actions = {
  add({ commit }, { userId, opt }) {
    return this.$axios.post(`users/${userId}/tickets`, opt)
  },
  getSign(context, opt) {
    return this.$axios.post(`images`, opt)
  },
  get(context, { ticketId, params }) {
    return this.$axios.get(`tickets/${ticketId}`, { params })
  },
  getAll(context, { userId, params }) {
    return this.$axios.get(`users/${userId}/tickets`, { params })
  },
  update(context, { ticketId, opt }) {
    return this.$axios.put(`tickets/${ticketId}`, opt)
  },
  close(context, { ticketId }) {
    return this.$axios.delete(`tickets/${ticketId}`)
  },
  reopen(context, { ticketId }) {
    return this.$axios.post(`tickets/${ticketId}/reopen`)
  },
  reply(context, { ticketId, content }) {
    return this.$axios.post(`tickets/${ticketId}`, { content })
  },
}
