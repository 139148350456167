export const state = () => ({
  signModalVisible: false,
  colorMode: 'light',
  tooltipTheme: 'dark',
  dlDomain: 'xconf2.oss-accelerate.aliyuncs.com',
  confDomain: 'getconf.oss-accelerate.aliyuncs.com',
  cfConfDomain: 'getconf.oss-accelerate.aliyuncs.com',
})

export const mutations = {
  signModalVisible(state, opt) {
    state.signModalVisible = opt.visible
  },
  colorMode(state, theme) {
    state.colorMode = theme
  },
  toolTipTheme(state, theme) {
    state.tooltipTheme = theme
  },
}

export const actions = {
  signModalVisible({ commit }, opt) {
    commit('signModalVisible', { visibile: true, ...opt })
  },
  colorMode({ commit }, theme) {
    commit('colorMode', theme)
  },
}
