export default function ({ redirect, app }) {
  let token = app.$utils.cookies.get('jwt_token')
  if (!token) {
    token = localStorage?.getItem('jwt_token')
  }
  if (!app.$utils.checkToken(token)) {
    localStorage?.removeItem('jwt_token')
    app.$utils.cookies.remove('jwt_token')
    app.$utils.cookies.remove('panda-news-read')
    app.$utils.cookies.remove('preferredServiceCate')
    return redirect('/')
  }
}
