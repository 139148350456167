let fakeStorage = {}
export default () => {
  if (!window?.localStorage) {
    console.log('localstorage is not found, wtf...')
    if (window) {
      window.localStorage = {
        setItem: (k, v) => (fakeStorage[k] = v),
        getItem: (k) => fakeStorage[k],
        removeItem: (k) => delete fakeStorage[k],
        clear: () => (fakeStorage = {}),
      }
    }
  }
}
