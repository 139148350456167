export const state = () => ({
  versions: null,
})

export const mutations = {
  versions(state, data) {
    state.versions = data
  },
}

export const actions = {
  async getCurrentRelease({ commit, state }, opt) {
    if (state.versions) {
      return state.versions
    }
    const { data } = await this.$axios.get(`funstuff/client_version`)
    commit('versions', data)
    return data
  },
  getUserClientHeartbeat({ commit, state }, opt) {
    return this.$axios.get(`client/heart_beat/${opt.userId}`, { params: opt.opt })
  },
  get({ commit, state }, { versionStr, params }) {
    return this.$axios.get(`client/update/${versionStr}`, params)
  },
  add({ commit, state }, opt) {
    return this.$axios.post(`client/update`, opt)
  },
  update({ commit, state }, version) {
    return this.$axios.put(`client/update/${version.version}`, version)
  },
  checkDeviceIdsBlocked({ commit, state }, params) {
    return this.$axios.get(`admin/blocked_device`, { params })
  },
  blockDevice({ commit, state }, opt) {
    return this.$axios.post(`admin/block_device`, opt)
  },
  unblockDevice({ commit, state }, opt) {
    return this.$axios.post(`admin/unblock_device`, opt)
  },
}
