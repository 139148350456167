export default function ({ app, store, route, redirect }) {
  // console.log('route is:', route)
  // console.log('route query is:', route.query)
  if (!route.query.sessionToken) {
    // console.log('no session token found in url query.')
    return
  }
  app.$utils.cookies.set('jwt_token', route.query.sessionToken)
  const query = { ...route.query }
  delete query.sessionToken
  redirect(route.path, query)
}
