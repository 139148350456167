
import { mapState } from 'vuex'
import BigNews from '@/components/BigNews.vue'
import Twitter from '~icons/mdi/twitter'
import Telegram from '~icons/mdi/telegram'

export default {
  name: 'Footer',
  components: {
    BigNews,
    Twitter,
    Telegram,
  },
  computed: {
    thisYear() {
      return new Date().getFullYear()
    },
    release() {
      // if (window.location.host.endsWith('panpan.pages.dev')) {
      //   // return window.location.host.split('.')[0]
      //   return 'CFPage'
      // }
      return this.$config.release
    },
    builtDate() {
      return this.$config.builtDate
    },
    hostName() {
      return this.$config.hostName
    },
    ...mapState({
      isAdmin: (state) => state.session.isAdmin,
    }),
  },
}
