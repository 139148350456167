
import IndexHeader from '@/components/IndexHeader.vue'
import IndexFooter from '@/components/IndexFooter.vue'
export default {
  name: 'DashboardLayout',
  components: {
    IndexHeader,
    IndexFooter,
  },
  middleware: ['check-session', 'restore-session', 'check-route-params', 'fetch-services'],
  head() {
    return {
      title: `${this.$t('site_name')} 控制面板`,
      meta: [
        { name: 'robots', content: 'noindex, nofollow, noarchive, nosnippet' },
        {
          name: 'theme-color',
          content: this.$colorMode.value === 'dark' ? this.$config.themeColor.dark : this.$config.themeColor.light,
        },
      ],
      bodyAttrs: {
        class: this.$i18n.locale || 'en',
      },
    }
  },
  // mounted() {
  //   // console.log('[Dashboard layout mounted]')
  //   // this.$utils.hello(123)
  // },
  // methods: {
  //   testFunc() {
  //     console.log('test func...')
  //   }
  // }
}
