export const state = () => ({
  serviceArray: [],
  allplatform: null,
  home: null,
  currentServiceCate: null,
  usableServiceCate: [],
  dataCache: {},
  servers: {},
  serviceCount: null,
})

export const mutations = {
  service(state, { cate, data }) {
    state[cate] = data
  },
  serviceArray(state, arr) {
    state.serviceArray = arr
  },
  currentServiceCate(state, cate) {
    state.currentServiceCate = cate
    this.$utils.cookies.set('preferredServiceCate', cate)
  },
  // 所有买过的服务，不管当前是否可用。
  boughtServiceCate(state, cateArr) {
    state.boughtService = cateArr
  },
  usableServiceCate(state, cateArr) {
    state.usableServiceCate = cateArr
  },
  dataCache(state, { userId, data }) {
    state.dataCache[userId] = {
      data,
      time: new Date().valueOf(),
    }
  },
  servers(state, { cate, data }) {
    state.servers[cate] = data
  },
  updateExtra(state, data) {
    Object.assign(state[state.currentServiceCate].extra, data)
  },
  serviceCount(state, count) {
    state.serviceCount = count
  },
}

export const actions = {
  async getCurrentUserService({ commit, rootState, state }, opt) {
    // console.log('getCurrentUserService, opt:', opt)
    const userId = rootState.user.user?.id || rootState.session.session?.id
    const cache = state.dataCache[userId]
    const now = new Date().valueOf()
    let data
    let cacheExpired
    if (!cache) {
      cacheExpired = true
    } else {
      cacheExpired = now - cache.time > 5 * 60 * 1000
      // console.log('found service cache, expired?', cacheExpired)
    }
    if (!opt?.refresh && !cacheExpired) {
      data = cache.data
    } else {
      data = (await this.$axios.get(`users/${userId}/services`)).data
      commit('dataCache', { userId, data })
    }
    const usableServiceCate = []
    const boughtServiceCate = []
    for (const service of data) {
      boughtServiceCate.push(service.cate)
      if (service.status === 'on') {
        usableServiceCate.push(service.cate)
      }
      commit('service', { cate: service.cate, data: service })
    }
    commit('boughtServiceCate', boughtServiceCate)
    commit('usableServiceCate', usableServiceCate)
    const pref = this.$cookiz.get('preferredServiceCate')
    if (pref && usableServiceCate.includes(pref)) {
      // 当前有记录下偏好，且该服务有效
      commit('currentServiceCate', pref)
    } else if (usableServiceCate.length > 0) {
      // 没有偏好/不再有效，那就切换到第一个可用的服务
      commit('currentServiceCate', usableServiceCate[0])
    } else if (boughtServiceCate.length > 0) {
      // 可用的服务也没有？那就默认切换到买过的第一个
      // 过了这个那就是啥都没有的了，那就没有默认的CurrentServiceCate了
      commit('currentServiceCate', boughtServiceCate[0])
    }
    commit('serviceArray', data)
    commit('serviceCount', data.length)
  },
  currentServiceCate({ commit }, cate) {
    commit('currentServiceCate', cate)
  },
  async getTrafficLog({ commit, rootState }, opt) {
    const { data } = await this.$axios.get(`users/${rootState.user.user.id}/services/${opt.serviceId}/traffic_log`, {
      params: opt.params,
    })
    return data
  },
  async getServers({ commit, rootState }, opt) {
    const { data } = await this.$axios.get(
      `users/${opt.userId || rootState.user.user.id}/services/${opt.serviceId}/lines`
    )
    return data
  },
  async updateServiceOpt({ commit, rootState }, opt) {
    const { data } = await this.$axios.put(
      `users/${opt.userId || rootState.user.user.id}/services/${opt.serviceId}`,
      opt.data
    )
    commit('updateExtra', opt.data)
    return data
  },
  async getAlreadyBoughtPremiumData({ state }, opt) {
    const { data } = await this.$axios.get(`users/${opt.userId}/services/pre_bought`)
    return data
  },
  changeBuylength({ commit }, opt) {
    return this.$axios.put(`users/${opt.userId}/services/${opt.serviceId}/buy_length`, opt.data)
  },
  resetServiceConfigInfo(context, opt) {
    return this.$axios.put(`users/${opt.userId}/services/${opt.serviceId}/reset`)
  },
  getIPLog(context, opt) {
    // duration: 3600
    return this.$axios.get(`users/${opt.userId}/services/${opt.serviceId}/ss_ip_log`, { params: opt.params })
  },
  getMonthlyUsage(context, opt) {
    return this.$axios.get(`users/${opt.userId}/services/${opt.serviceId}/monthly_usage`, { params: opt.params })
  },
  async getServiceCount({ commit }, opt) {
    // console.log('running getServiceCount')
    const { data } = await this.$axios.get(`users/${opt.userId}/service_count`)
    // console.log('got data:', data)
    commit('serviceCount', data.count)
    // console.log('return data.')
    return data.count
  },
  updateExtra({ commit }, data) {
    commit('updateExtra', data)
  },
}
