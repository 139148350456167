import { Loading } from 'element-ui'

const validSubPath = ['billing', 'settings', 'support', 'tickets']
const redirWithSubPath = ({ redirect, route, store, error }) => {
  const hasSubPath = checkValidSubpath(route.params.userId)
  if (!store.state.session?.session?.id) {
    return error('undefined session id')
  }
  // console.log('redirWithSubPath, session.id is:', store.state.session.session.id)
  const finalPath = '/dashboard/' + store.state.session.session.id + `${hasSubPath ? `/${route.params.userId}` : ''}`
  // console.log('redirect to:', finalPath)
  // console.log('with query:', route.query)
  return redirect({
    path: finalPath,
    query: route.query,
  })
}

const startLoading = () => {
  return Loading.service({
    lock: true,
    text: 'Loading',
    spinner: 'el-icon-loading',
    background: 'rgba(0, 0, 0, 0.7)',
  })
}

const checkValidSubpath = (fullSubpath) => (fullSubpath ? validSubPath.includes(fullSubpath.split('/')[0]) : false)

export default async function ({ redirect, route, store, error }) {
  let isEmail = false
  const isValidSubpath = checkValidSubpath(route.params.userId)
  if (route.params.userId && route.params.userId.includes('@')) {
    // check if this is an email address.
    console.log(`userId is an email address.`)
    isEmail = true
  }
  if (store.state.session.session?.id && !route.params.userId) {
    // console.log(1)
    console.log(`found session, but no userId specified, redirect to user's own page.`)
    store.dispatch('user/setUserData', { user: store.state.session.session })
    return redirWithSubPath({ redirect, route, store, error })
  }
  let loading
  if (!store.state.session.session?.id) {
    loading = startLoading()
    try {
      await store.dispatch('session/restoreSession')
      loading?.close()
    } catch (e) {
      loading?.close()
      if (!e.isAxiosError) {
        console.error(e)
        return
      }
      // console.log(e.response.data)
      let errTitle = 'Error'
      if (e.response?.status >= 500) {
        errTitle = 'Server error. Please try again later'
      }
      return error({
        statusCode: e.response?.status || e,
        message: e.response?.data || e,
        title: errTitle,
      })
    }
  }
  // No user id specified, redirect to its own page /dashboard/session.id
  if (!route.params.userId) {
    // console.log(3)
    console.log('no userId specified, redirect to his own page')
    store.dispatch('user/setUserData', { user: store.state.session.session })
    // console.log('redir dst:', route.path + '/' + store.state.session.session.id)
    return redirWithSubPath({ redirect, route, store, error })
  }
  // Specified a userId, and it is his own. this means `session` equals `user`
  if (store.state.session.session?.id === Number(route.params.userId) || isValidSubpath) {
    // console.log(4)
    // console.log('user data will be the same as session data')
    store.dispatch('user/setUserData', { user: store.state.session.session })
    if (isValidSubpath) {
      // console.log(4.1)
      console.log(`found valid sub path.`)
      return redirWithSubPath({ redirect, route, store, error })
    }
  } else if (!store.state.session.isAdmin) {
    // console.log(5)
    // well, viewing other user's page, let's check if he's admin
    // okay this guy is delusional.
    // redirect back to his own page.
    console.log(`redirect back to user's own page`)
    return redirWithSubPath({ redirect, route, store, error })
  } else {
    // console.log(6)
    // alright, admin's here.
    // fetch this user's profile now.
    // console.log('gonna fetch user data')
    if (!loading) {
      loading = startLoading()
    }
    try {
      console.log('get user info from urlParam:', route.params.userId)
      await store.dispatch('user/get', { userId: route.params.userId })
    } catch (e) {
      console.log(e)
      loading?.close()
      let errTitle = 'Error'
      if (e.response?.status >= 500) {
        errTitle = 'Server error. Please try again later'
      }
      return error({
        statusCode: e.response.status,
        message: e.response.data?.message || e.response.data,
        title: errTitle,
      })
    }
    console.log('user info found')
    loading?.close()
    if (isEmail) {
      console.log('yep is email.')
      return redirect({
        path: '/dashboard/' + store.state.user.user.id,
        query: route.query,
      })
    }
  }
}
