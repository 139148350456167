import { render, staticRenderFns } from "./_dashboard.vue?vue&type=template&id=16fc6995&scoped=true&lang=pug&"
import script from "./_dashboard.vue?vue&type=script&lang=js&"
export * from "./_dashboard.vue?vue&type=script&lang=js&"
import style0 from "./_dashboard.vue?vue&type=style&index=0&id=16fc6995&prod&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "16fc6995",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IndexHeader: require('/opt/buildhome/repo/components/IndexHeader.vue').default,IndexFooter: require('/opt/buildhome/repo/components/IndexFooter.vue').default})
