import { Loading } from 'element-ui'
const exceptRoutes = [
  'pricing',
  'dashboard-userid-settings',
  'dashboard-userid-billing',
  'dashboard-userid-support',
  'dashboard-userid-profile',
  'dashboard-userid-tickets',
  'dashboard-userid-tickets-add',
]
export default async function ({ redirect, store, route, error }) {
  // console.log(store.state.user.user)
  // console.log(`fetch-services of user: ${store.state.user.user.id}`)
  if (store.state.session.loggedIn) {
    const loading = Loading.service({
      lock: true,
      text: 'Loading',
      spinner: 'el-icon-loading',
      background: 'rgba(0, 0, 0, 0.7)',
    })
    await store.dispatch('service/getCurrentUserService')
    loading?.close()
  }
  if (!store.state.service.serviceArray.length && !exceptRoutes.includes(route.name.toLowerCase())) {
    redirect({
      path: '/pricing',
      query: {
        asUser: store.state.user.user.id,
      },
    })
  }
}
