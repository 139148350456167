
export default {
  name: 'ColorModePicker2',
  props: {
    prefix: {
      type: String,
      default: '',
    },
    postfix: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      themes: {
        light: {
          switchIcon: 'el-icon-moon-night',
        },
        dark: {
          switchIcon: 'el-icon-sunrise-1',
        },
      },
    }
  },
  computed: {
    themeIcon() {
      return this.themes[this.$colorMode.value]?.switchIcon
    },
    darkDark() {
      return this.$colorMode.value === 'dark' && 'darkmode-toggled'
    },
  },
  mounted() {
    this.updateVuexColorMode()
  },
  methods: {
    changeTheme(newTheme) {
      this.$colorMode.preference = newTheme
      this.updateVuexColorMode(newTheme)
    },
    switchTheme() {
      if (this.$colorMode.preference !== 'light') {
        return this.changeTheme('light')
      }
      this.changeTheme('dark')
    },
    updateVuexColorMode(theme) {
      this.$store.dispatch('base/colorMode', theme || this.$colorMode.preference)
    },
  },
}
