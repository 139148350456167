
export default {
  name: 'NuxtError',
  props: {
    error: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      exactErrors: [503, 500, 404, 403, 400],
    }
  },
  head() {
    return {
      title: this.$t(this.title || this.message),
      meta: [
        {
          name: 'viewport',
          content: 'width=device-width,initial-scale=1.0,minimum-scale=1.0',
        },
      ],
    }
  },
  computed: {
    statusCode() {
      return this.error?.statusCode || this.error?.code || 500
    },
    message() {
      return this.error.message
    },
    title() {
      return this.error.title
    },
    errorImgCode() {
      if (this.exactErrors.includes(Number(this.statusCode))) {
        return { code: this.statusCode, default: false }
      }
      if (Number(this.statusCode) >= 500) {
        return { code: 500, default: false }
      }
      if (Number(this.statusCode) >= 400) {
        return { code: 400, default: false }
      }
      return { code: this.statusCode, default: true }
    },
    errorImg() {
      if (this.errorImgCode.default) {
        return false
      }
      return require(`~/static/img/errors/${this.errorImgCode.code}.svg`)
    },
  },
}
