
import debounce from 'lodash.debounce'
import { mapState } from 'vuex'
import SignupLoginModal from '@/components/SignupLoginModal.vue'
import LangSwitcher from '@/components/LangSwitcher.vue'
import SimpleCurrentServiceSelector from '@/components/SimpleCurrentServiceSelector.vue'

export default {
  name: 'IndexHeader',
  // middleware: ['restore-session'],
  components: {
    SignupLoginModal,
    LangSwitcher,
    SimpleCurrentServiceSelector,
  },
  data() {
    return {
      signModalVisible: false,
      defaultState: 'signup',
      view: {
        atTopOfPage: true,
      },
    }
  },
  head() {
    return { title: `${this.$t('site_name')}` }
  },
  computed: {
    inDashboard() {
      // console.log('-->', this.$route)
      return this.$route.path.startsWith('/dashboard/')
    },
    showTicket() {
      if (this.isAdmin) {
        return true
      }
      return !this.user?.extra?.tk_suspend
    },
    refLink() {
      return `/my-ref`
    },
    dashboardLink() {
      return `/dashboard${this.userId ? `/${this.userId}` : ''}`
    },
    // always show dashboard link when user has jwt_token in cookies
    showDashboardLink() {
      const token = this.$cookiz.get('jwt_token')
      return this.$utils.checkToken(token) || this.loggedIn
    },
    tooltipTheme() {
      return this.$colorMode.preference === 'light' ? 'dark' : 'light'
    },
    viewingSelf() {
      if (!this.isAdmin) {
        return false
      }
      return this.session?.id === this.user?.id
    },
    baseLink() {
      return this.dashboardLink
    },
    pricingLink() {
      return `/pricing${this.user?.id ? `?asUser=${this.user.id}` : ''}`
    },
    topUpLink() {
      const query = ['mode=topUp']
      if (this.user?.id) {
        query.push(`asUser=${this.user.id}`)
      }
      return `/pricing?${query.join('&')}`
    },
    hasService() {
      return this.$store.state.service.serviceCount > 0
    },
    dlLink() {
      if (this.showDashboardLink && this.hasService) {
        return `${this.baseLink}?tab=guides`
      }
      return '/download'
    },
    billLink() {
      return `${this.baseLink}/billing`
    },
    settingLink() {
      return `${this.baseLink}/settings`
    },
    supportLink() {
      return `${this.baseLink}/support`
    },
    profileLink() {
      return `${this.baseLink}/profile`
    },
    ticketLink() {
      return `${this.baseLink}/tickets`
    },
    showDropdown() {
      // console.log(
      //   'computing showDropdown',
      //   this.$utils.cookies.get('jwt_token'),
      //   this.$utils.checkToken(this.$utils.cookies.get('jwt_token')),
      //   this.loggedIn
      // )
      return this.$utils.checkToken(this.$utils.cookies.get('jwt_token')) || this.loggedIn
    },
    userId() {
      return this.user?.id || this.session?.id
    },
    userMoney() {
      let money = this.session?.money
      let currency = this.session?.balance_currency || 'cny'
      if ('money' in this.user) {
        money = this.user?.money
        currency = this.user?.balance_currency || 'cny'
      }
      return `${currency === 'eur' ? '€' : '¥'}${money}`
    },
    ...mapState({
      session: (state) => state.session.session,
      isAdmin: (state) => state.session.isAdmin,
      loggedIn: (state) => state.session.loggedIn,
      user: (state) => state.user.user,
    }),
  },
  watch: {
    session() {
      this.$intercom.shutdown()
      this.startIntercom()
    },
  },
  created() {
    // console.log('this.$intercom', this.$intercom)
    // this.$intercom.once('ready', this.fixIntercomCss)
  },
  beforeMount() {
    window.addEventListener('scroll', debounce(this.handleScroll, 100))
  },
  async mounted() {
    this.startIntercom()
    let token = this.$cookiz.get('jwt_token')
    if (!token) {
      token = localStorage?.getItem('jwt_token')
    }
    if (token) {
      // console.log('header mounted, jwt_token found')
      try {
        await this.$store.dispatch('session/restoreSession')
        // console.log('done session restore from header')
        // console.log(this.session)
        // eslint-disable-next-line no-unused-vars
        const count = await this.$store.dispatch('service/getServiceCount', {
          userId: this.user?.id || this.session?.id,
        })
        // console.log('getServiceCount', count)
      } catch (e) {
        console.log('header mounted error:', e)
      }
    }
  },
  methods: {
    go(arg) {
      this.$router.push(arg)
    },
    goToDashboard() {
      if (this.$route.name !== 'dashboard-userId') {
        return this.go(this.dashboardLink)
      }
    },
    dropdownClicked() {
      if (Object.prototype.hasOwnProperty.call(window, 'ontouchstart')) {
        return
      }
      return this.goToDashboard()
    },
    showLogin() {
      this.defaultState = 'login'
      this.signModalVisible = true
    },
    showSignup() {
      this.defaultState = 'signup'
      this.signModalVisible = true
    },
    logout() {
      this.$confirm(this.$t('settings_page.logout.warning.content'), this.$t('settings_page.logout.warning.title'), {
        confirmButtonText: this.$t('settings_page.logout.warning.title'),
        cancelButtonText: this.$t('service_selector.cancel'),
        type: 'warning',
      }).then(
        () => {
          this.$store.dispatch('session/logout')
          this.$notify({
            type: 'success',
            title: this.$t('settings_page.logout.suc'),
            position: 'top-right',
          })
          window.location.reload()
        },
        () => {}
      )
    },
    startIntercom() {
      this.$intercom.boot(this.session || {})
      // eslint-disable-next-line camelcase
      if (this.session?.extra?.tk_suspend) {
        this.$intercom.hide()
      }
    },
    switchIsAdmin() {
      this.$store.commit('session/isAdmin', !this.isAdmin)
    },
    fixIntercomCss() {
      console.log('intercom css fix:')
      const frames = document.getElementsByTagName('iframe')
      let frame = null
      for (let i = 0; i < frames.length; i++) {
        console.log(frames[i].title)
        if (frames[i].title === 'Intercom') {
          frame = frames[i]
        }
      }
      const doc = frame.contentDocument
      setTimeout(() => {
        console.log(doc.body.innerHTML)
      }, 1500)
      doc.body.innerHTML =
        doc.body.innerHTML +
        `<style>.intercom-conversation-body-main-group > :last-of-type {
        display: none;
      }</style>`
    },
    handleScroll() {
      // when the user scrolls, check the pageYOffset
      if (window.pageYOffset > 0) {
        // user is scrolled
        if (this.view.atTopOfPage) {
          window?.requestAnimationFrame(() => (this.view.atTopOfPage = false))
        }
      } else {
        window?.requestAnimationFrame(() => (this.view.atTopOfPage = true))
      }
    },
  },
}
